//LAYOUT

body, .page-wrapper {
	overflow-x: hidden; 
}

body {
	font-family: 'Open Sans';
	src: local('Helvetica'), local('Helvetica'), Lato;
	font-display: swap;
}

.container-main {
	background-color: $light;
	@extend .py-5;
}

.container-main-lg {
	@extend .py-lg-7;
	@extend .py-6;
}



.cards {
	@extend .container;
	.col {
		margin-bottom: $grid-gutter-width;
	}
}

.customers {
	@extend .container;
}

.bg-section {
	position:relative;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
	}
}

.bg-confetti {
	&::before {
		z-index: -1;
		background: linear-gradient(#fff 0, rgba(255,255,255,.8) 100%), url('../img/bg-confetti.svg') 50% 100% repeat-x;
		background-size: cover, 800px;
	}
}

.bg-round {
	&::before {
		top: 0;
		background: url('../img/bg-round.svg') 50% 0 repeat $primary;
		background-size: 80px;
	}
}

.section-full {
	@extend .py-5; 
	@extend .py-lg-7;
}

.section-full-dark {
	@extend .section-full;
	@extend .text-white;
}

.section-form {
 &:not(:first-child) {
 	@extend .mb-4;
 	}
}

.form-control {
	@extend .rounded;
}

////////////////////////////

///TYPOGRAPHY

h1, h2, h3 {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 500;
}

// h1 {
// 	src: local('Helvetica'), local('Helvetica'), Rubik;
// 	font-display: swap;
// }

h1 {
	@extend .text-white;
	@include font-size(3.5rem);
	.small {
		@include font-size(2rem);
	}
}

h2 {
	@include font-size(2.5rem);
}

// .h2-var {
// 	@include font-size(3.5rem);
// }

dt {
	font-size: 1.2rem;
}


////////////////////////////

//UTILITIES

.align-right {
	right: 0;
	left: auto;
}

.text-faded {
	opacity: .75;
}

.text-darker {
	color: $gray-400;
}


.d-flex-eq {
	flex-basis: 0;
	@extend .flex-grow-1;
}

.bg-special {
	background-color: $gray-700;
}


//// LINKS

.link-dark {
	color: $white;
	text-decoration: $link-decoration;
	background-color: transparent; // Remove the gray background on active links in IE 10.

	@include hover() {
		color: $white;
		text-decoration: $link-hover-decoration;
	}
}

.link-no-decoration {
	&, &:hover {
		text-decoration: none;
	}
}

// Equal title and content width

.header-width-clamp-md {
	@include media-breakpoint-up(md) {
		.max-content-width {
		width: max-content;
	}
	.min-content-width {
		width: min-content;
		}
	}
}

////////////////////////////

//// COMPONENTS

// BUTTONS

.btn-group-toggle {
	> .btn,
		> .btn-group > .btn {
			&:not(:last-child) {
				@extend .mr-2;
			}
		}
	> .btn-options {
		@extend .mb-3;
	}
	}


.btn-special {
	@extend .btn;
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $rounded-pill);
	@extend .btn-light;
	@extend .border-dark;
	letter-spacing: .2px;
}

.btn-buy {
	@extend .btn;
	@extend .btn-lg;
	@extend .btn-primary;
	@extend .font-weight-bold;
	letter-spacing: .2px;
}

.pill {
	@extend .btn;
	@extend .btn-sm;
	@extend .btn-outline-gray;
	@extend .px-3;
	@extend .rounded-pill;
}

.btn-cta {
	@extend .btn;
	@extend .btn-lg; 
	@extend .btn-primary;
	@extend .font-weight-bold;
	min-width: 200px;
}

.btn-options {
	@include button-outline-variant($input-border-color);
	color: $input-color;
	@extend .rounded-lg;
}



// ICONS

.icon {
	width: 48px;
	height: 48px;
}

.icon-rounded {
	@extend .d-inline-flex;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .rounded-circle;
	width: 2.5rem;
	height: 2.5rem;
	&.bg-primary {
		@extend .text-white;
	}
}

.icon-rounded-lg {
	width: 4rem;
	height: 4rem;
}

// NAV

.navbar-nav {
	padding-inline-start: 0;
}

.nav-item {
	@extend .text-right;
	&:not(:last-child) {

	}
}

/// NAME COLOR THEMES

$name-colors: () !default;
$name-colors: map-merge(
	(
	"blue":		$blue,
	"cyan":		$cyan,
	"dkblue":	$dkblue,
	"dkcyan":	$dkcyan,
	"dkred":	$dkred,
	"dkyellow":	$dkyellow,
	"green":	$green,
	"orange":	$orange,
	"pink":		$pink,
	"purple":	#554a94,
	"red":		$red,
	"yellow":	$yellow
	),
	$name-colors
);

@mixin bg-gradient-name-variant($parent, $color, $ignore-warning: false) {
	#{$parent} {
		background: $color linear-gradient(180deg, mix(#000, $color, 15%), $color) repeat-x !important;
	}
}

@each $color, $value in $name-colors {
	@include bg-variant(".name-#{$color}", $value, true);
	@include bg-gradient-name-variant(".name-gradient-#{$color}", $value, true);
}


//CARDS

.card {
	@extend .shadow;
	@extend .rounded-lg;
	@extend .h-100;
}


/// NAME CARDS

.name-only {
	@extend .position-relative;
	// @extend .d-flex;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .py-4;
	@extend .px-4;
	h3 {
		@extend .mb-0;
		@include font-size(2rem);
		font-weight: normal;
	}
}

.name {
	@extend .position-relative;
	@extend .d-flex;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .py-5;
	@extend .px-4;
	@extend .text-white;
	@extend .rounded-lg;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	h3 {
		@extend .mb-0;
		@include font-size(2.5rem);
		@extend .position-relative;
		z-index: 1;
	}
}

.name-link {
	@extend .link-dark;
	@extend .text-faded;
	@extend .stretched-link;
	@extend .link-no-decoration;
	&::after {
		transition: background .4s;
	}
	&:hover{
		&::after {
			background: rgba(0,0,0,.1);
		}
	}

	@include hover-focus {
		text-decoration: none;
	}
}

.name-attr-type {
	@extend .d-flex;
	@extend .border-bottom;
	.attr-type-item {
		@extend .bg-light;
		border-radius: 0;
		transition: background .4s;
		@extend .text-center;
		@extend .font-weight-bold;
		@extend .py-3;
		@extend .px-4;
		flex-basis: 0;
		@extend .flex-grow-1;
		&:first-child {
			@extend .border-right;
		}
		@include hover-focus {
			text-decoration: none;
			@extend .bg-gray-medium;
		}
	}
}

.name-attr-feel {
	@extend .px-4;
	display: grid;
	grid-template-columns: min-content auto min-content;
	grid-template-rows: auto;
	gap: 1rem;
}

.feel-level {
	@extend .d-flex;
	@extend .align-items-center;
	@extend .position-relative;
	@extend .flex-grow-1;
	.slider {
		width: $font-size-sm;
		height: $font-size-sm;
		background-color: $yellow;
		@extend .rounded-circle;
	}
	&:after {
		content: '';
		height: 1px;
		width: 100%;
		background-color: $yellow;
		@extend .position-absolute;
		top: 50%;
	}
}

// .name-buy {
// 	@extend .px-4;
// 	@extend .py-4;
// 	@extend .text-center;
// 	a {
// 		@extend .btn;
// 		@extend .btn-lg; 
// 		@extend .btn-secondary; 
// 		@extend .rounded-pill;
// 	}
// }

///FEATURES

.features {
	background-color: $gray-light;
	@extend .pt-5; 
	@extend .pt-lg-6;
}

.feature-option {
	@extend .d-flex;
	@extend .mb-2; 
	@extend .p-lg-4;
	// &.active {
	// 	@extend .shadow-sm;
	// 	@extend .bg-white;
	// }
}


.feature-option-text {
	flex:1;
	// @extend .flex-lg-fill;
}


.feature-option-icon {
	@extend .icon-rounded; 
	@extend .icon-rounded-lg; 
	@extend .bg-primary;
	@extend .mr-4;
}


.feature-option-right {
	.feature-option-text {
		@extend .order-1;
		@extend .order-lg-0;
		@extend .text-lg-right;
	}
	.feature-option-icon {
		@extend .ml-lg-4;
		@extend .mr-lg-0; 
	}
}

/// SHOWCASE

.showcase {
	@extend .d-flex;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	 &::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.showcase-img {
	@extend .shadow;
	@extend .bg-white;
	@extend .p-2;
	@extend .mx-2;
	@extend .mb-6;
	img {
		width: 400px;
	}
}

/// BOXES

.box-white-card {
	@extend .bg-white;
	@extend .py-5;
	@extend .px-4;
	@extend .h-100;
}

///HEADER

.header {
	@extend .text-center;
	@extend .text-white;
	// background: radial-gradient(circle,#473365 0,#232136 59%,#1e193c 100%);
	background: linear-gradient(#2f3041 0,#17171e 59%,#1b1c24 100%);
	.nav-container {
		@extend .container;
		@extend .py-3;
	}
}


.header-home {
	@extend .position-relative;
	// background: url("../img/pattern.svg") repeat, radial-gradient(circle,#472975 0,#232136 59%,#1e193c 100%);
	// background-size: 180px, auto;
	background: url("../img/pattern.svg") repeat, radial-gradient(circle,#2f3041 0,#17171e 59%,#1b1c24 100%);
	background-size: 180px, auto;
	h1 {
		font-family: 'Courier New', monospace;
		@include font-size(3rem);
		// src: local('Helvetica'), local('Helvetica'), Itim;
		// font-display: swap;
	}
	.hero-text-lead {
		@extend .mt-5;
		color: #cabfe3;
	}
}

.hero-text {
	@extend .pt-md-4;
	@extend .pb-md-5;
	@extend .pt-3;
	@extend .pb-4;
}

.hero-text-lead {
	max-width: 800px;
	@extend .container;
	@extend .mx-auto;
	@extend .text-center;
	@extend .text-faded;
}

.header-name {
	h1 {
		@extend .mb-0;
	}
	.hero-text-lead {
		@extend .mt-3;
	}
}

.logo {
	margin-right: $navbar-padding-x;
	font-size: $navbar-brand-font-size;
	line-height: inherit;
	vertical-align: middle;
	white-space: nowrap;
	min-width: 100px;
	background-repeat: no-repeat;
	@include hover-focus {
		text-decoration: none;
		color: transparent;
	}
}



///FOOTER

footer {
	color: $light;
	@extend .small; 
	background-color: #1b1c24;
	// .container {
	//	 @include media-breakpoint-down(lg) {
	//	 width: 100%;
	//	 max-width: none;
	//	 padding-right: 24px;
	//	 padding-left: 24px;
	//	 margin-right: auto;
	//	 margin-left: auto;
	//	 }
	// .bg-bleed-after:after {
	//	 display: none;
	// }
	// }
}



///FORMS

$form-floating-height:            add(3.5rem, $input-height-border) !default;
$form-floating-padding-x:         $input-padding-x !default;
$form-floating-padding-y:         1rem !default;
$form-floating-input-padding-t:   1.625rem !default;
$form-floating-input-padding-b:   .625rem !default;
$form-floating-label-opacity:     .65 !default;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out !default;

.form-control {
	@extend .d-block;
	@extend .w-100;
	padding: .5rem 1.5rem;
	@include font-size($font-size-base);
	line-height: $line-height-base;
	// line-height: 1.72;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0;
}

// .form-label {
// 	@extend .font-weight-bold;
// 	@extend .text-muted;
// }

.form-floating {
	@extend .mb-4;
}

.form-floating {
  position: relative;

  > .form-control {
    height: $form-floating-height;
    padding: $form-floating-padding-y $form-floating-padding-x;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    @include transition($form-floating-transition);
  }

  // stylelint-disable no-duplicate-selectors
  > .form-control {
    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-select {
  	height: $form-floating-height;
    padding-top: $form-floating-input-padding-b;
    padding-bottom: $form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: $form-floating-label-opacity;
      transform: $form-floating-label-transform;
    }
  }
  // stylelint-enable no-duplicate-selectors
}

////CATEGORY NAV

.category-nav {
	@extend .container;
	@extend .pt-4;
	@extend .d-flex;
 }

.category-nav-items {
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .flex-wrap;
	.pill {
		@extend .mb-3;
		&:not(:last-child) {
			@extend .mr-3;
		}
	}
}


/// CAROUSEL

.carousel-indicators {
	position: relative;
	margin-right: auto;
	margin-left: auto;
}

.carousel-bullets [data-target] {
	box-sizing: content-box;
	width: 30px;
	height: 30px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: $primary;
	background-clip: padding-box;
	border: 10px solid transparent;
	border-radius: 50%;
	opacity: .5;
	transition: opacity .6s ease;
}

.carousel-bullets .active {
	opacity: 1;
}

.carousel-inner {
	@extend .d-flex;
	@extend .pb-3;
}

.carousel-control {
	@extend .position-relative;
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .align-items-center;
	@extend .px-3;
	z-index: 1;
}


#carousel-names-sold {
/*
	code by Iatek LLC 2018 - CC 2.0 License - Attribution required
	code customized by Azmind.com
*/

@media (min-width: 768px) and (max-width: 991px) {
	/* Show 4th slide on md if col-md-4*/
	.carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
	position: absolute;
	top: 0;
	right: -33.3333%;	/*change this with javascript in the future*/
	z-index: -1;
	display: block;
	visibility: visible;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	/* Show 3rd slide on sm if col-sm-6*/
	.carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
	position: absolute;
	top: 0;
	right: -50%;	/*change this with javascript in the future*/
	z-index: -1;
	display: block;
	visibility: visible;
	}
}
@media (min-width: 576px) {
	.carousel-item {
	margin-right: 0;
	}
	/* show 2 items */
	.carousel-inner .active + .carousel-item {
	display: block;
	}
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
	transition: none;
	}
	.carousel-inner .carousel-item-next {
	position: relative;
	transform: translate3d(0, 0, 0);
	}
	/* left or forward direction */
	.active.carousel-item-left + .carousel-item-next.carousel-item-left,
	.carousel-item-next.carousel-item-left + .carousel-item,
	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
	position: relative;
	transform: translate3d(-100%, 0, 0);
	visibility: visible;
	}
	/* farthest right hidden item must be also positioned for animations */
	.carousel-inner .carousel-item-prev.carousel-item-right {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	display: block;
	visibility: visible;
	}
	/* right or prev direction */
	.active.carousel-item-right + .carousel-item-prev.carousel-item-right,
	.carousel-item-prev.carousel-item-right + .carousel-item,
	.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
	position: relative;
	transform: translate3d(100%, 0, 0);
	visibility: visible;
	display: block;
	visibility: visible;
	}
}
/* MD */
@media (min-width: 768px) {
	/* show 3rd of 3 item slide */
	.carousel-inner .active + .carousel-item + .carousel-item {
	display: block;
	}
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
	transition: none;
	}
	.carousel-inner .carousel-item-next {
	position: relative;
	transform: translate3d(0, 0, 0);
	}
	/* left or forward direction */
	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
	position: relative;
	transform: translate3d(-100%, 0, 0);
	visibility: visible;
	}
	/* right or prev direction */
	.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
	position: relative;
	transform: translate3d(100%, 0, 0);
	visibility: visible;
	display: block;
	visibility: visible;
	}
}
/* LG */
@media (min-width: 991px) {
	/* show 4th item */
	.carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
	display: block;
	}
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
	transition: none;
	}
	/* Show 5th slide on lg if col-lg-3 */
	.carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
	position: absolute;
	top: 0;
	right: -25%;	/*change this with javascript in the future*/
	z-index: -1;
	display: block;
	visibility: visible;
	}
	/* left or forward direction */
	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
	position: relative;
	transform: translate3d(-100%, 0, 0);
	visibility: visible;
	}
	/* right or prev direction //t - previous slide direction last item animation fix */
	.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
	position: relative;
	transform: translate3d(100%, 0, 0);
	visibility: visible;
	display: block;
	visibility: visible;
	}
}

}
//// ACCORDION

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  // @include font-size($font-size-base);
  color: $accordion-button-color;
  background-color: $accordion-button-bg;
  border: $accordion-border-width solid $accordion-border-color;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &.collapsed {

    // border-bottom-width: 0;

  }

  &:not(.collapsed) {
    color: $accordion-button-active-color;
    background-color: $accordion-button-active-bg;
    border-bottom-width: 0;


    &::after {
      background-image: escape-svg($accordion-button-active-icon);
      transform: $accordion-icon-transform;
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    margin-left: auto;
    content: "";
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: $accordion-button-focus-border-color;
    border-bottom-width: 0;
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;
    &.collapsing {
    	border-bottom-color: transparent;
    }
    &.collapsed {
    	border-bottom-width: $accordion-border-width;
    }
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  &:first-of-type {
    .accordion-button {
      @include border-top-radius($accordion-border-radius);
    }
  }

  &:last-of-type {
    .accordion-button {
      // Only set a border-radius on the last item if the accordion is collapsed
      &.collapsed {
        border-bottom-width: $accordion-border-width;
        @include border-bottom-radius($accordion-border-radius);
      }
    }
    .accordion-collapse {
      border-bottom-width: $accordion-border-width;
      @include border-bottom-radius($accordion-border-radius);
    }
  }
}

.accordion-collapse {
  border: solid $accordion-border-color;
  border-width: $accordion-border-width;
  border-top-width: 0;
}

.accordion-body {
  padding: $accordion-body-padding-y $accordion-body-padding-x;
  	background-color: $accordion-button-bg;
}



// /// SPINNER - not used

// .spinner-btn {
//	 width: 200px;
//	 height: 50px;
// }

// .spinner-wrap {
//	 position: absolute;
//	 top: 50%;
//	 margin-top: -25px;
//	 left: 50%;
//	 margin-left: -100px;
// }

// @keyframes spinner {
//	 to {transform: rotate(360deg);}
// }
 


// .spinner-border {
//	 display: inline-block;
//	 width: $spinner-width-sm;
//	 height: $spinner-height-sm;
//	 margin-right: .5rem;
//	 vertical-align: text-bottom;
//	 border-right-color: transparent;
//	 border-radius: 50%;
//	 border: 1px solid $light;
//	 border-top-color: $primary;
//	 animation: spinner .6s linear infinite;
// }