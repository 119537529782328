/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #0089be;
  --indigo: #423b6b;
  --purple: #423b6b;
  --red: #bf3600;
  --orange: #d66b30;
  --yellow: #ffbd28;
  --green: #3f9d19;
  --cyan: #19bbbf;
  --white: #fff;
  --gray-light: #f7f7f8;
  --gray: #6c778e;
  --gray-dark: #47576c;
  --primary: #0089be;
  --secondary: #47576c;
  --success: #3f9d19;
  --info: #19bbbf;
  --warning: #ffbd28;
  --danger: #bf3600;
  --light: #f7f7f8;
  --dark: #47576c;
  --gray: #6c778e;
  --gray-medium: #e3e1e9;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1400px;
  --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #344254;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0089be;
  text-decoration: underline;
  background-color: transparent;
}

a:hover {
  color: #005272;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c778e;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: calc(2px + 3ex + 2px);
}

h1, .h1 {
  font-size: 3.3rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.455rem + 2.46vw) ;
  }
}

h2, .h2 {
  font-size: 2.2rem;
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.345rem + 1.14vw) ;
  }
}

h3, .h3 {
  font-size: 1.925rem;
}

@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3175rem + 0.81vw) ;
  }
}

h4, .h4 {
  font-size: 1.65rem;
}

@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.29rem + 0.48vw) ;
  }
}

h5, .h5 {
  font-size: 1.375rem;
}

@media (max-width: 1200px) {
  h5, .h5 {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

h6, .h6 {
  font-size: 1.21rem;
}

.lead {
  font-size: 1.375rem;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .lead {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: calc(2px + 3ex + 2px);
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw) ;
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: calc(2px + 3ex + 2px);
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw) ;
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: calc(2px + 3ex + 2px);
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw) ;
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: calc(2px + 3ex + 2px);
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw) ;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small,
footer {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.375rem;
}

@media (max-width: 1200px) {
  .blockquote {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c778e;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #cfccd8;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c778e;
}

.container, .cards, .customers, .header .nav-container, .hero-text-lead, .category-nav,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .cards, .customers, .header .nav-container, .hero-text-lead, .category-nav, .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container, .cards, .customers, .header .nav-container, .hero-text-lead, .category-nav, .container-sm, .container-md {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .container, .cards, .customers, .header .nav-container, .hero-text-lead, .category-nav, .container-sm, .container-md, .container-lg {
    max-width: 90%;
  }
}

@media (min-width: 1400px) {
  .container, .cards, .customers, .header .nav-container, .hero-text-lead, .category-nav, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1340px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1, .feature-option-right .feature-option-text {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0, .feature-option-right .feature-option-text {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1400px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #344254;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #cfccd8;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #cfccd8;
}

.table tbody + tbody {
  border-top: 2px solid #cfccd8;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #cfccd8;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #cfccd8;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #344254;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8deed;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ac2dd;
}

.table-hover .table-primary:hover {
  background-color: #a4d5e8;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a4d5e8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cbd0d6;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9fa8b3;
}

.table-hover .table-secondary:hover {
  background-color: #bdc3cb;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #bdc3cb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c9e4bf;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9bcc87;
}

.table-hover .table-success:hover {
  background-color: #badcad;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #badcad;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfeced;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #87dcde;
}

.table-hover .table-info:hover {
  background-color: #abe6e7;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abe6e7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffedc3;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdd8f;
}

.table-hover .table-warning:hover {
  background-color: #ffe5aa;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe5aa;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edc7b8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #de967a;
}

.table-hover .table-danger:hover {
  background-color: #e8b7a4;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e8b7a4;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfbfb;
}

.table-hover .table-light:hover {
  background-color: #f0f0f0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f0f0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cbd0d6;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9fa8b3;
}

.table-hover .table-dark:hover {
  background-color: #bdc3cb;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bdc3cb;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #d6d9df;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #b3b8c4;
}

.table-hover .table-gray:hover {
  background-color: #c8ccd4;
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #c8ccd4;
}

.table-gray-medium,
.table-gray-medium > th,
.table-gray-medium > td {
  background-color: #f7f7f9;
}

.table-gray-medium th,
.table-gray-medium td,
.table-gray-medium thead th,
.table-gray-medium tbody + tbody {
  border-color: #f0eff4;
}

.table-hover .table-gray-medium:hover {
  background-color: #e8e8ee;
}

.table-hover .table-gray-medium:hover > td,
.table-hover .table-gray-medium:hover > th {
  background-color: #e8e8ee;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #47576c;
  border-color: #566a83;
}

.table .thead-light th {
  color: #342061;
  background-color: #f1f1f3;
  border-color: #cfccd8;
}

.table-dark {
  color: #fff;
  background-color: #47576c;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #566a83;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.7em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #344254;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cfccd8;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #344254;
}

.form-control:focus {
  color: #344254;
  background-color: #fff;
  border-color: #3fc9ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c778e;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c778e;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c778e;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c778e;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c778e;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f1f1f3;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #344254;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.375rem;
  line-height: 1.7;
}

@media (max-width: 1200px) {
  .col-form-label-lg {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.9625rem;
  line-height: 1.4;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1.1rem;
  line-height: 1.7;
  color: #344254;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.4em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  line-height: 1.4;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.7em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.375rem;
  line-height: 1.7;
  border-radius: 0.3rem;
}

@media (max-width: 1200px) {
  .form-control-lg {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c778e;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #3f9d19;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.9625rem;
  line-height: 1.7;
  color: #fff;
  background-color: rgba(63, 157, 25, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3f9d19;
  padding-right: calc(1.7em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233f9d19' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.25rem) center;
  background-size: calc(0.85em + 0.5rem) calc(0.85em + 0.5rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3f9d19;
  box-shadow: 0 0 0 0.2rem rgba(63, 157, 25, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 1rem);
  background-position: top calc(0.425em + 0.25rem) right calc(0.425em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #3f9d19;
  padding-right: calc(0.75em + 2.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%2347576c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233f9d19' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.85em + 0.5rem) calc(0.85em + 0.5rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #3f9d19;
  box-shadow: 0 0 0 0.2rem rgba(63, 157, 25, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3f9d19;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3f9d19;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #3f9d19;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #51c920;
  background-color: #51c920;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(63, 157, 25, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3f9d19;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3f9d19;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #3f9d19;
  box-shadow: 0 0 0 0.2rem rgba(63, 157, 25, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bf3600;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.9625rem;
  line-height: 1.7;
  color: #fff;
  background-color: rgba(191, 54, 0, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #bf3600;
  padding-right: calc(1.7em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23bf3600' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23bf3600' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.25rem) center;
  background-size: calc(0.85em + 0.5rem) calc(0.85em + 0.5rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #bf3600;
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 0, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1rem);
  background-position: top calc(0.425em + 0.25rem) right calc(0.425em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #bf3600;
  padding-right: calc(0.75em + 2.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%2347576c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23bf3600' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23bf3600' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.85em + 0.5rem) calc(0.85em + 0.5rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #bf3600;
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #bf3600;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #bf3600;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #bf3600;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f24400;
  background-color: #f24400;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 0, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #bf3600;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #bf3600;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #bf3600;
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 0, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .btn-special, .btn-buy, .pill, .btn-cta {
  display: inline-block;
  font-weight: 400;
  color: #344254;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  line-height: 1.7;
  border-radius: 50rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn, .btn-special, .btn-buy, .pill, .btn-cta {
    transition: none;
  }
}

.btn:hover, .btn-special:hover, .btn-buy:hover, .pill:hover, .btn-cta:hover {
  color: #344254;
  text-decoration: none;
}

.btn:focus, .btn-special:focus, .btn-buy:focus, .pill:focus, .btn-cta:focus, .btn.focus, .focus.btn-special, .focus.btn-buy, .focus.pill, .focus.btn-cta {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.btn.disabled, .disabled.btn-special, .disabled.btn-buy, .disabled.pill, .disabled.btn-cta, .btn:disabled, .btn-special:disabled, .btn-buy:disabled, .pill:disabled, .btn-cta:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled), .btn-special:not(:disabled):not(.disabled), .btn-buy:not(:disabled):not(.disabled), .pill:not(:disabled):not(.disabled), .btn-cta:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, a.disabled.btn-special, a.disabled.btn-buy, a.disabled.pill, a.disabled.btn-cta,
fieldset:disabled a.btn,
fieldset:disabled a.btn-special,
fieldset:disabled a.btn-buy,
fieldset:disabled a.pill,
fieldset:disabled a.btn-cta {
  pointer-events: none;
}

.btn-primary, .btn-buy, .btn-cta {
  color: #fff;
  background-color: #0089be;
  border-color: #0089be;
}

.btn-primary:hover, .btn-buy:hover, .btn-cta:hover {
  color: #fff;
  background-color: #006d98;
  border-color: #00648b;
}

.btn-primary:focus, .btn-buy:focus, .btn-cta:focus, .btn-primary.focus, .focus.btn-buy, .focus.btn-cta {
  color: #fff;
  background-color: #006d98;
  border-color: #00648b;
  box-shadow: 0 0 0 0.2rem rgba(38, 155, 200, 0.5);
}

.btn-primary.disabled, .disabled.btn-buy, .disabled.btn-cta, .btn-primary:disabled, .btn-buy:disabled, .btn-cta:disabled {
  color: #fff;
  background-color: #0089be;
  border-color: #0089be;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-buy:not(:disabled):not(.disabled):active, .btn-cta:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-buy:not(:disabled):not(.disabled).active, .btn-cta:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.btn-buy,
.show > .dropdown-toggle.btn-cta {
  color: #fff;
  background-color: #00648b;
  border-color: #005b7e;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-buy:not(:disabled):not(.disabled):active:focus, .btn-cta:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-buy:not(:disabled):not(.disabled).active:focus, .btn-cta:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.btn-buy:focus,
.show > .dropdown-toggle.btn-cta:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 155, 200, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #384455;
  border-color: #333e4d;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #384455;
  border-color: #333e4d;
  box-shadow: 0 0 0 0.2rem rgba(99, 112, 130, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #333e4d;
  border-color: #2e3846;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 112, 130, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #3f9d19;
  border-color: #3f9d19;
}

.btn-success:hover {
  color: #fff;
  background-color: #327c14;
  border-color: #2d7112;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #327c14;
  border-color: #2d7112;
  box-shadow: 0 0 0 0.2rem rgba(92, 172, 60, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #3f9d19;
  border-color: #3f9d19;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d7112;
  border-color: #296610;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 172, 60, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #19bbbf;
  border-color: #19bbbf;
}

.btn-info:hover {
  color: #fff;
  background-color: #159a9d;
  border-color: #138f92;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #159a9d;
  border-color: #138f92;
  box-shadow: 0 0 0 0.2rem rgba(60, 197, 201, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #19bbbf;
  border-color: #19bbbf;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #138f92;
  border-color: #128487;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 197, 201, 0.5);
}

.btn-warning {
  color: #344254;
  background-color: #ffbd28;
  border-color: #ffbd28;
}

.btn-warning:hover {
  color: #344254;
  background-color: #ffb102;
  border-color: #f4a900;
}

.btn-warning:focus, .btn-warning.focus {
  color: #344254;
  background-color: #ffb102;
  border-color: #f4a900;
  box-shadow: 0 0 0 0.2rem rgba(225, 171, 47, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #344254;
  background-color: #ffbd28;
  border-color: #ffbd28;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #344254;
  background-color: #f4a900;
  border-color: #e7a000;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 171, 47, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #bf3600;
  border-color: #bf3600;
}

.btn-danger:hover {
  color: #fff;
  background-color: #992b00;
  border-color: #8c2800;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #992b00;
  border-color: #8c2800;
  box-shadow: 0 0 0 0.2rem rgba(201, 84, 38, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #bf3600;
  border-color: #bf3600;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #8c2800;
  border-color: #7f2400;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 84, 38, 0.5);
}

.btn-light, .btn-special {
  color: #344254;
  background-color: #f7f7f8;
  border-color: #f7f7f8;
}

.btn-light:hover, .btn-special:hover {
  color: #344254;
  background-color: #e3e3e6;
  border-color: #dcdce0;
}

.btn-light:focus, .btn-special:focus, .btn-light.focus, .focus.btn-special {
  color: #344254;
  background-color: #e3e3e6;
  border-color: #dcdce0;
  box-shadow: 0 0 0 0.2rem rgba(218, 220, 223, 0.5);
}

.btn-light.disabled, .disabled.btn-special, .btn-light:disabled, .btn-special:disabled {
  color: #344254;
  background-color: #f7f7f8;
  border-color: #f7f7f8;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-special:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-special:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle,
.show > .dropdown-toggle.btn-special {
  color: #344254;
  background-color: #dcdce0;
  border-color: #d5d5da;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-special:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-special:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus,
.show > .dropdown-toggle.btn-special:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 220, 223, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-dark:hover {
  color: #fff;
  background-color: #384455;
  border-color: #333e4d;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #384455;
  border-color: #333e4d;
  box-shadow: 0 0 0 0.2rem rgba(99, 112, 130, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #333e4d;
  border-color: #2e3846;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 112, 130, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #6c778e;
  border-color: #6c778e;
}

.btn-gray:hover {
  color: #fff;
  background-color: #5b6578;
  border-color: #565f71;
}

.btn-gray:focus, .btn-gray.focus {
  color: #fff;
  background-color: #5b6578;
  border-color: #565f71;
  box-shadow: 0 0 0 0.2rem rgba(130, 139, 159, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: #fff;
  background-color: #6c778e;
  border-color: #6c778e;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #565f71;
  border-color: #50596a;
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 139, 159, 0.5);
}

.btn-gray-medium {
  color: #344254;
  background-color: #e3e1e9;
  border-color: #e3e1e9;
}

.btn-gray-medium:hover {
  color: #344254;
  background-color: #cecbd9;
  border-color: #c8c4d3;
}

.btn-gray-medium:focus, .btn-gray-medium.focus {
  color: #344254;
  background-color: #cecbd9;
  border-color: #c8c4d3;
  box-shadow: 0 0 0 0.2rem rgba(201, 201, 211, 0.5);
}

.btn-gray-medium.disabled, .btn-gray-medium:disabled {
  color: #344254;
  background-color: #e3e1e9;
  border-color: #e3e1e9;
}

.btn-gray-medium:not(:disabled):not(.disabled):active, .btn-gray-medium:not(:disabled):not(.disabled).active,
.show > .btn-gray-medium.dropdown-toggle {
  color: #344254;
  background-color: #c8c4d3;
  border-color: #c1bcce;
}

.btn-gray-medium:not(:disabled):not(.disabled):active:focus, .btn-gray-medium:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(201, 201, 211, 0.5);
}

.btn-outline-primary {
  color: #0089be;
  border-color: #0089be;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0089be;
  border-color: #0089be;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0089be;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0089be;
  border-color: #0089be;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.5);
}

.btn-outline-secondary {
  color: #47576c;
  border-color: #47576c;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 87, 108, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #47576c;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 87, 108, 0.5);
}

.btn-outline-success {
  color: #3f9d19;
  border-color: #3f9d19;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #3f9d19;
  border-color: #3f9d19;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 157, 25, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #3f9d19;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #3f9d19;
  border-color: #3f9d19;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 157, 25, 0.5);
}

.btn-outline-info {
  color: #19bbbf;
  border-color: #19bbbf;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #19bbbf;
  border-color: #19bbbf;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 187, 191, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #19bbbf;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #19bbbf;
  border-color: #19bbbf;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 187, 191, 0.5);
}

.btn-outline-warning {
  color: #ffbd28;
  border-color: #ffbd28;
}

.btn-outline-warning:hover {
  color: #344254;
  background-color: #ffbd28;
  border-color: #ffbd28;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 189, 40, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbd28;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #344254;
  background-color: #ffbd28;
  border-color: #ffbd28;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 189, 40, 0.5);
}

.btn-outline-danger {
  color: #bf3600;
  border-color: #bf3600;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #bf3600;
  border-color: #bf3600;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 0, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #bf3600;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #bf3600;
  border-color: #bf3600;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 54, 0, 0.5);
}

.btn-outline-light {
  color: #f7f7f8;
  border-color: #f7f7f8;
}

.btn-outline-light:hover {
  color: #344254;
  background-color: #f7f7f8;
  border-color: #f7f7f8;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 247, 248, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f7f7f8;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #344254;
  background-color: #f7f7f8;
  border-color: #f7f7f8;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 247, 248, 0.5);
}

.btn-outline-dark {
  color: #47576c;
  border-color: #47576c;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 87, 108, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #47576c;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #47576c;
  border-color: #47576c;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 87, 108, 0.5);
}

.btn-outline-gray, .pill {
  color: #6c778e;
  border-color: #6c778e;
}

.btn-outline-gray:hover, .pill:hover {
  color: #fff;
  background-color: #6c778e;
  border-color: #6c778e;
}

.btn-outline-gray:focus, .pill:focus, .btn-outline-gray.focus, .focus.pill {
  box-shadow: 0 0 0 0.2rem rgba(108, 119, 142, 0.5);
}

.btn-outline-gray.disabled, .disabled.pill, .btn-outline-gray:disabled, .pill:disabled {
  color: #6c778e;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled):active, .pill:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active, .pill:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle,
.show > .dropdown-toggle.pill {
  color: #fff;
  background-color: #6c778e;
  border-color: #6c778e;
}

.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .pill:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus, .pill:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus,
.show > .dropdown-toggle.pill:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 119, 142, 0.5);
}

.btn-outline-gray-medium {
  color: #e3e1e9;
  border-color: #e3e1e9;
}

.btn-outline-gray-medium:hover {
  color: #344254;
  background-color: #e3e1e9;
  border-color: #e3e1e9;
}

.btn-outline-gray-medium:focus, .btn-outline-gray-medium.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 225, 233, 0.5);
}

.btn-outline-gray-medium.disabled, .btn-outline-gray-medium:disabled {
  color: #e3e1e9;
  background-color: transparent;
}

.btn-outline-gray-medium:not(:disabled):not(.disabled):active, .btn-outline-gray-medium:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-medium.dropdown-toggle {
  color: #344254;
  background-color: #e3e1e9;
  border-color: #e3e1e9;
}

.btn-outline-gray-medium:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-medium:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 225, 233, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0089be;
  text-decoration: underline;
}

.btn-link:hover {
  color: #005272;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c778e;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .btn-special, .btn-group-lg > .btn-buy, .btn-group-lg > .pill, .btn-group-lg > .btn-cta, .btn-buy, .btn-cta {
  padding: 0.75rem 1.5rem;
  font-size: 1.375rem;
  line-height: 1.7;
  border-radius: 50rem;
}

@media (max-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn, .btn-group-lg > .btn-special, .btn-group-lg > .btn-buy, .btn-group-lg > .pill, .btn-group-lg > .btn-cta, .btn-buy, .btn-cta {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .btn-special, .btn-group-sm > .btn-buy, .btn-group-sm > .pill, .btn-group-sm > .btn-cta, .pill {
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  line-height: 1.4;
  border-radius: 20rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.1rem;
  color: #344254;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f1f3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #344254;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #2a3644;
  text-decoration: none;
  background-color: #f7f7f8;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0089be;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c778e;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.9625rem;
  color: #6c778e;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #344254;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn, .btn-group > .btn-special, .btn-group > .btn-buy, .btn-group > .pill, .btn-group > .btn-cta,
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-special,
.btn-group-vertical > .btn-buy,
.btn-group-vertical > .pill,
.btn-group-vertical > .btn-cta {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover, .btn-group > .btn-special:hover, .btn-group > .btn-buy:hover, .btn-group > .pill:hover, .btn-group > .btn-cta:hover,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn-special:hover,
.btn-group-vertical > .btn-buy:hover,
.btn-group-vertical > .pill:hover,
.btn-group-vertical > .btn-cta:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn-special:focus, .btn-group > .btn-buy:focus, .btn-group > .pill:focus, .btn-group > .btn-cta:focus, .btn-group > .btn:active, .btn-group > .btn-special:active, .btn-group > .btn-buy:active, .btn-group > .pill:active, .btn-group > .btn-cta:active, .btn-group > .btn.active, .btn-group > .active.btn-special, .btn-group > .active.btn-buy, .btn-group > .active.pill, .btn-group > .active.btn-cta,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn-special:focus,
.btn-group-vertical > .btn-buy:focus,
.btn-group-vertical > .pill:focus,
.btn-group-vertical > .btn-cta:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn-special:active,
.btn-group-vertical > .btn-buy:active,
.btn-group-vertical > .pill:active,
.btn-group-vertical > .btn-cta:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.btn-special,
.btn-group-vertical > .active.btn-buy,
.btn-group-vertical > .active.pill,
.btn-group-vertical > .active.btn-cta {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-special:not(:first-child), .btn-group > .btn-buy:not(:first-child), .btn-group > .pill:not(:first-child), .btn-group > .btn-cta:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-special:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-buy:not(:last-child):not(.dropdown-toggle), .btn-group > .pill:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-cta:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .btn-special,
.btn-group > .btn-group:not(:last-child) > .btn-buy,
.btn-group > .btn-group:not(:last-child) > .pill,
.btn-group > .btn-group:not(:last-child) > .btn-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-special:not(:first-child), .btn-group > .btn-buy:not(:first-child), .btn-group > .pill:not(:first-child), .btn-group > .btn-cta:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .btn-special,
.btn-group > .btn-group:not(:first-child) > .btn-buy,
.btn-group > .btn-group:not(:first-child) > .pill,
.btn-group > .btn-group:not(:first-child) > .btn-cta {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .btn-special + .dropdown-toggle-split, .btn-group-sm > .btn-buy + .dropdown-toggle-split, .btn-group-sm > .pill + .dropdown-toggle-split, .btn-group-sm > .btn-cta + .dropdown-toggle-split, .pill + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .btn-special + .dropdown-toggle-split, .btn-group-lg > .btn-buy + .dropdown-toggle-split, .btn-group-lg > .pill + .dropdown-toggle-split, .btn-group-lg > .btn-cta + .dropdown-toggle-split, .btn-buy + .dropdown-toggle-split, .btn-cta + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}


.btn-group-vertical > .btn,
.btn-group-vertical > .btn-special,
.btn-group-vertical > .btn-buy,
.btn-group-vertical > .pill,
.btn-group-vertical > .btn-cta,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-special:not(:first-child), .btn-group-vertical > .btn-buy:not(:first-child), .btn-group-vertical > .pill:not(:first-child), .btn-group-vertical > .btn-cta:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-special:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-buy:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .pill:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-cta:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .btn-special,
.btn-group-vertical > .btn-group:not(:last-child) > .btn-buy,
.btn-group-vertical > .btn-group:not(:last-child) > .pill,
.btn-group-vertical > .btn-group:not(:last-child) > .btn-cta {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-special:not(:first-child), .btn-group-vertical > .btn-buy:not(:first-child), .btn-group-vertical > .pill:not(:first-child), .btn-group-vertical > .btn-cta:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn-special,
.btn-group-vertical > .btn-group:not(:first-child) > .btn-buy,
.btn-group-vertical > .btn-group:not(:first-child) > .pill,
.btn-group-vertical > .btn-group:not(:first-child) > .btn-cta {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-special, .btn-group-toggle > .btn-buy, .btn-group-toggle > .pill, .btn-group-toggle > .btn-cta,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .btn-special,
.btn-group-toggle > .btn-group > .btn-buy,
.btn-group-toggle > .btn-group > .pill,
.btn-group-toggle > .btn-group > .btn-cta {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-special input[type="radio"], .btn-group-toggle > .btn-buy input[type="radio"], .btn-group-toggle > .pill input[type="radio"], .btn-group-toggle > .btn-cta input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-special input[type="checkbox"],
.btn-group-toggle > .btn-buy input[type="checkbox"],
.btn-group-toggle > .pill input[type="checkbox"],
.btn-group-toggle > .btn-cta input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn-special input[type="radio"],
.btn-group-toggle > .btn-group > .btn-buy input[type="radio"],
.btn-group-toggle > .btn-group > .pill input[type="radio"],
.btn-group-toggle > .btn-group > .btn-cta input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn-special input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn-buy input[type="checkbox"],
.btn-group-toggle > .btn-group > .pill input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn-cta input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn, .input-group-prepend .btn-special, .input-group-prepend .btn-buy, .input-group-prepend .pill, .input-group-prepend .btn-cta,
.input-group-append .btn,
.input-group-append .btn-special,
.input-group-append .btn-buy,
.input-group-append .pill,
.input-group-append .btn-cta {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus, .input-group-prepend .btn-special:focus, .input-group-prepend .btn-buy:focus, .input-group-prepend .pill:focus, .input-group-prepend .btn-cta:focus,
.input-group-append .btn:focus,
.input-group-append .btn-special:focus,
.input-group-append .btn-buy:focus,
.input-group-append .pill:focus,
.input-group-append .btn-cta:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn-special + .btn, .input-group-prepend .btn-buy + .btn, .input-group-prepend .pill + .btn, .input-group-prepend .btn-cta + .btn, .input-group-prepend .btn + .btn-special, .input-group-prepend .btn-special + .btn-special, .input-group-prepend .btn-buy + .btn-special, .input-group-prepend .pill + .btn-special, .input-group-prepend .btn-cta + .btn-special, .input-group-prepend .btn + .btn-buy, .input-group-prepend .btn-special + .btn-buy, .input-group-prepend .btn-buy + .btn-buy, .input-group-prepend .pill + .btn-buy, .input-group-prepend .btn-cta + .btn-buy, .input-group-prepend .btn + .pill, .input-group-prepend .btn-special + .pill, .input-group-prepend .btn-buy + .pill, .input-group-prepend .pill + .pill, .input-group-prepend .btn-cta + .pill, .input-group-prepend .btn + .btn-cta, .input-group-prepend .btn-special + .btn-cta, .input-group-prepend .btn-buy + .btn-cta, .input-group-prepend .pill + .btn-cta, .input-group-prepend .btn-cta + .btn-cta,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .btn-special + .input-group-text,
.input-group-prepend .btn-buy + .input-group-text,
.input-group-prepend .pill + .input-group-text,
.input-group-prepend .btn-cta + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .btn-special,
.input-group-prepend .input-group-text + .btn-buy,
.input-group-prepend .input-group-text + .pill,
.input-group-prepend .input-group-text + .btn-cta,
.input-group-append .btn + .btn,
.input-group-append .btn-special + .btn,
.input-group-append .btn-buy + .btn,
.input-group-append .pill + .btn,
.input-group-append .btn-cta + .btn,
.input-group-append .btn + .btn-special,
.input-group-append .btn-special + .btn-special,
.input-group-append .btn-buy + .btn-special,
.input-group-append .pill + .btn-special,
.input-group-append .btn-cta + .btn-special,
.input-group-append .btn + .btn-buy,
.input-group-append .btn-special + .btn-buy,
.input-group-append .btn-buy + .btn-buy,
.input-group-append .pill + .btn-buy,
.input-group-append .btn-cta + .btn-buy,
.input-group-append .btn + .pill,
.input-group-append .btn-special + .pill,
.input-group-append .btn-buy + .pill,
.input-group-append .pill + .pill,
.input-group-append .btn-cta + .pill,
.input-group-append .btn + .btn-cta,
.input-group-append .btn-special + .btn-cta,
.input-group-append .btn-buy + .btn-cta,
.input-group-append .pill + .btn-cta,
.input-group-append .btn-cta + .btn-cta,
.input-group-append .btn + .input-group-text,
.input-group-append .btn-special + .input-group-text,
.input-group-append .btn-buy + .input-group-text,
.input-group-append .pill + .input-group-text,
.input-group-append .btn-cta + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .btn-special,
.input-group-append .input-group-text + .btn-buy,
.input-group-append .input-group-text + .pill,
.input-group-append .input-group-text + .btn-cta {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #344254;
  text-align: center;
  white-space: nowrap;
  background-color: #f1f1f3;
  border: 1px solid #cfccd8;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.7em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .btn-special,
.input-group-lg > .input-group-prepend > .btn-buy,
.input-group-lg > .input-group-prepend > .pill,
.input-group-lg > .input-group-prepend > .btn-cta,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .btn-special,
.input-group-lg > .input-group-append > .btn-buy,
.input-group-lg > .input-group-append > .pill,
.input-group-lg > .input-group-append > .btn-cta {
  padding: 0.5rem 1rem;
  font-size: 1.375rem;
  line-height: 1.7;
  border-radius: 0.3rem;
}

@media (max-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .custom-select,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-prepend > .btn-special,
  .input-group-lg > .input-group-prepend > .btn-buy,
  .input-group-lg > .input-group-prepend > .pill,
  .input-group-lg > .input-group-prepend > .btn-cta,
  .input-group-lg > .input-group-append > .btn,
  .input-group-lg > .input-group-append > .btn-special,
  .input-group-lg > .input-group-append > .btn-buy,
  .input-group-lg > .input-group-append > .pill,
  .input-group-lg > .input-group-append > .btn-cta {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.4em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .btn-special,
.input-group-sm > .input-group-prepend > .btn-buy,
.input-group-sm > .input-group-prepend > .pill,
.input-group-sm > .input-group-prepend > .btn-cta,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .btn-special,
.input-group-sm > .input-group-append > .btn-buy,
.input-group-sm > .input-group-append > .pill,
.input-group-sm > .input-group-append > .btn-cta {
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  line-height: 1.4;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .btn-special, .input-group > .input-group-prepend > .btn-buy, .input-group > .input-group-prepend > .pill, .input-group > .input-group-prepend > .btn-cta,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .btn-special,
.input-group > .input-group-append:not(:last-child) > .btn-buy,
.input-group > .input-group-append:not(:last-child) > .pill,
.input-group > .input-group-append:not(:last-child) > .btn-cta,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .btn-special:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .btn-buy:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .pill:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .btn-cta:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .btn-special, .input-group > .input-group-append > .btn-buy, .input-group > .input-group-append > .pill, .input-group > .input-group-append > .btn-cta,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-special,
.input-group > .input-group-prepend:not(:first-child) > .btn-buy,
.input-group > .input-group-prepend:not(:first-child) > .pill,
.input-group > .input-group-prepend:not(:first-child) > .btn-cta,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .btn-special:not(:first-child),
.input-group > .input-group-prepend:first-child > .btn-buy:not(:first-child),
.input-group > .input-group-prepend:first-child > .pill:not(:first-child),
.input-group > .input-group-prepend:first-child > .btn-cta:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.87rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.435rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0089be;
  background-color: #0089be;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3fc9ff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #72d8ff;
  border-color: #72d8ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c778e;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f1f1f3;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.435rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.435rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0089be;
  background-color: #0089be;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 137, 190, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 137, 190, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 137, 190, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.435rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 137, 190, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.7em + 1rem + 2px);
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #344254;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%2347576c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #cfccd8;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #3fc9ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.custom-select:focus::-ms-value {
  color: #344254;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c778e;
  background-color: #f1f1f3;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #344254;
}

.custom-select-sm {
  height: calc(1.4em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.9625rem;
}

.custom-select-lg {
  height: calc(1.7em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.375rem;
}

@media (max-width: 1200px) {
  .custom-select-lg {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.7em + 1rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.7em + 1rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #3fc9ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f1f1f3;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.7em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #344254;
  background-color: #fff;
  border: 1px solid #cfccd8;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.7em + 1rem);
  padding: 0.5rem 1rem;
  line-height: 1.7;
  color: #344254;
  content: "Browse";
  background-color: #f1f1f3;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0089be;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #72d8ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #cfccd8;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0089be;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #72d8ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #cfccd8;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0089be;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #72d8ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #cfccd8;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #cfccd8;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c778e;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #cfccd8;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f1f1f3 #f1f1f3 #cfccd8;
}

.nav-tabs .nav-link.disabled {
  color: #6c778e;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #342061;
  background-color: #fff;
  border-color: #cfccd8 #cfccd8 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0089be;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.navbar .container, .navbar .cards, .navbar .customers, .navbar .header .nav-container, .header .navbar .nav-container, .navbar .hero-text-lead, .navbar .category-nav,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.26625rem;
  padding-bottom: 0.26625rem;
  margin-right: 0;
  font-size: 1.375rem;
  line-height: inherit;
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .navbar-brand {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50rem;
}

@media (max-width: 1200px) {
  .navbar-toggler {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .cards, .navbar-expand-sm > .customers, .header .navbar-expand-sm > .nav-container, .navbar-expand-sm > .hero-text-lead, .navbar-expand-sm > .category-nav,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container, .navbar-expand-sm > .cards, .navbar-expand-sm > .customers, .header .navbar-expand-sm > .nav-container, .navbar-expand-sm > .hero-text-lead, .navbar-expand-sm > .category-nav,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .cards, .navbar-expand-md > .customers, .header .navbar-expand-md > .nav-container, .navbar-expand-md > .hero-text-lead, .navbar-expand-md > .category-nav,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container, .navbar-expand-md > .cards, .navbar-expand-md > .customers, .header .navbar-expand-md > .nav-container, .navbar-expand-md > .hero-text-lead, .navbar-expand-md > .category-nav,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .cards, .navbar-expand-lg > .customers, .header .navbar-expand-lg > .nav-container, .navbar-expand-lg > .hero-text-lead, .navbar-expand-lg > .category-nav,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container, .navbar-expand-lg > .cards, .navbar-expand-lg > .customers, .header .navbar-expand-lg > .nav-container, .navbar-expand-lg > .hero-text-lead, .navbar-expand-lg > .category-nav,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .cards, .navbar-expand-xl > .customers, .header .navbar-expand-xl > .nav-container, .navbar-expand-xl > .hero-text-lead, .navbar-expand-xl > .category-nav,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container, .navbar-expand-xl > .cards, .navbar-expand-xl > .customers, .header .navbar-expand-xl > .nav-container, .navbar-expand-xl > .hero-text-lead, .navbar-expand-xl > .category-nav,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .cards, .navbar-expand > .customers, .header .navbar-expand > .nav-container, .navbar-expand > .hero-text-lead, .navbar-expand > .category-nav,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container, .navbar-expand > .cards, .navbar-expand > .customers, .header .navbar-expand > .nav-container, .navbar-expand > .hero-text-lead, .navbar-expand > .category-nav,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 20px;
    margin-bottom: 0;
    margin-left: 20px;
  }
}

.card-group > .card {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f1f1f3;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c778e;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c778e;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0089be;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfccd8;
}

.page-link:hover {
  z-index: 2;
  color: #005272;
  text-decoration: none;
  background-color: #f1f1f3;
  border-color: #cfccd8;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 137, 190, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0089be;
  border-color: #0089be;
}

.page-item.disabled .page-link {
  color: #6c778e;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #cfccd8;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.375rem;
  line-height: 1.7;
}

@media (max-width: 1200px) {
  .pagination-lg .page-link {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  line-height: 1.4;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.15rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004763;
  background-color: #cce7f2;
  border-color: #b8deed;
}

.alert-primary hr {
  border-top-color: #a4d5e8;
}

.alert-primary .alert-link {
  color: #002230;
}

.alert-secondary {
  color: #252d38;
  background-color: #dadde2;
  border-color: #cbd0d6;
}

.alert-secondary hr {
  border-top-color: #bdc3cb;
}

.alert-secondary .alert-link {
  color: #111419;
}

.alert-success {
  color: #21520d;
  background-color: #d9ebd1;
  border-color: #c9e4bf;
}

.alert-success hr {
  border-top-color: #badcad;
}

.alert-success .alert-link {
  color: #0f2606;
}

.alert-info {
  color: #0d6163;
  background-color: #d1f1f2;
  border-color: #bfeced;
}

.alert-info hr {
  border-top-color: #abe6e7;
}

.alert-info .alert-link {
  color: #073536;
}

.alert-warning {
  color: #856215;
  background-color: #fff2d4;
  border-color: #ffedc3;
}

.alert-warning hr {
  border-top-color: #ffe5aa;
}

.alert-warning .alert-link {
  color: #59420e;
}

.alert-danger {
  color: #631c00;
  background-color: #f2d7cc;
  border-color: #edc7b8;
}

.alert-danger hr {
  border-top-color: #e8b7a4;
}

.alert-danger .alert-link {
  color: #300e00;
}

.alert-light {
  color: #808081;
  background-color: #fdfdfe;
  border-color: #fdfdfd;
}

.alert-light hr {
  border-top-color: #f0f0f0;
}

.alert-light .alert-link {
  color: #676767;
}

.alert-dark {
  color: #252d38;
  background-color: #dadde2;
  border-color: #cbd0d6;
}

.alert-dark hr {
  border-top-color: #bdc3cb;
}

.alert-dark .alert-link {
  color: #111419;
}

.alert-gray {
  color: #383e4a;
  background-color: #e2e4e8;
  border-color: #d6d9df;
}

.alert-gray hr {
  border-top-color: #c8ccd4;
}

.alert-gray .alert-link {
  color: #22262d;
}

.alert-gray-medium {
  color: #767579;
  background-color: #f9f9fb;
  border-color: #f7f7f9;
}

.alert-gray-medium hr {
  border-top-color: #e8e8ee;
}

.alert-gray-medium .alert-link {
  color: #5d5c5f;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #342061;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #342061;
  text-decoration: none;
  background-color: #f7f7f8;
}

.list-group-item-action:active {
  color: #344254;
  background-color: #f1f1f3;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c778e;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0089be;
  border-color: #0089be;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004763;
  background-color: #b8deed;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004763;
  background-color: #a4d5e8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004763;
  border-color: #004763;
}

.list-group-item-secondary {
  color: #252d38;
  background-color: #cbd0d6;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #252d38;
  background-color: #bdc3cb;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #252d38;
  border-color: #252d38;
}

.list-group-item-success {
  color: #21520d;
  background-color: #c9e4bf;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #21520d;
  background-color: #badcad;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #21520d;
  border-color: #21520d;
}

.list-group-item-info {
  color: #0d6163;
  background-color: #bfeced;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0d6163;
  background-color: #abe6e7;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0d6163;
  border-color: #0d6163;
}

.list-group-item-warning {
  color: #856215;
  background-color: #ffedc3;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856215;
  background-color: #ffe5aa;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856215;
  border-color: #856215;
}

.list-group-item-danger {
  color: #631c00;
  background-color: #edc7b8;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #631c00;
  background-color: #e8b7a4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #631c00;
  border-color: #631c00;
}

.list-group-item-light {
  color: #808081;
  background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #808081;
  background-color: #f0f0f0;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #808081;
  border-color: #808081;
}

.list-group-item-dark {
  color: #252d38;
  background-color: #cbd0d6;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #252d38;
  background-color: #bdc3cb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #252d38;
  border-color: #252d38;
}

.list-group-item-gray {
  color: #383e4a;
  background-color: #d6d9df;
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #383e4a;
  background-color: #c8ccd4;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #383e4a;
  border-color: #383e4a;
}

.list-group-item-gray-medium {
  color: #767579;
  background-color: #f7f7f9;
}

.list-group-item-gray-medium.list-group-item-action:hover, .list-group-item-gray-medium.list-group-item-action:focus {
  color: #767579;
  background-color: #e8e8ee;
}

.list-group-item-gray-medium.list-group-item-action.active {
  color: #fff;
  background-color: #767579;
  border-color: #767579;
}

.close {
  float: right;
  font-size: 1.65rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.29rem + 0.48vw) ;
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c778e;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #cfccd8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #cfccd8;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #6c778e;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #6c778e;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3e%3cpath fill='%236c778e' d='M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z' /%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3e%3cpath fill='%236c778e' d='M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M8.6,16.6L13.2,12L8.6,7.4L10,6L16,12L10,18L8.6,16.6Z' /%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary, .feature-option-icon {
  background-color: #0089be;
}

a.bg-primary:hover, a.feature-option-icon:hover, a.bg-primary:focus, a.feature-option-icon:focus,
button.bg-primary:hover,
button.feature-option-icon:hover,
button.bg-primary:focus,
button.feature-option-icon:focus {
  background-color: #00648b;
}

.bg-secondary {
  background-color: #47576c;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #333e4d;
}

.bg-success {
  background-color: #3f9d19;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2d7112;
}

.bg-info {
  background-color: #19bbbf;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #138f92;
}

.bg-warning {
  background-color: #ffbd28;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f4a900;
}

.bg-danger {
  background-color: #bf3600;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #8c2800;
}

.bg-light, .name-attr-type .attr-type-item {
  background-color: #f7f7f8;
}

a.bg-light:hover, .name-attr-type a.attr-type-item:hover, a.bg-light:focus, .name-attr-type a.attr-type-item:focus,
button.bg-light:hover,
.name-attr-type button.attr-type-item:hover,
button.bg-light:focus,
.name-attr-type button.attr-type-item:focus {
  background-color: #dcdce0;
}

.bg-dark {
  background-color: #47576c;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #333e4d;
}

.bg-gray {
  background-color: #6c778e;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #565f71;
}

.bg-gray-medium, .name-attr-type .attr-type-item:hover, .name-attr-type .attr-type-item:focus {
  background-color: #e3e1e9;
}

a.bg-gray-medium:hover, .name-attr-type a.attr-type-item:hover, a.bg-gray-medium:focus, .name-attr-type a.attr-type-item:focus,
button.bg-gray-medium:hover,
.name-attr-type button.attr-type-item:hover,
button.bg-gray-medium:focus,
.name-attr-type button.attr-type-item:focus {
  background-color: #c8c4d3;
}

.bg-white, .showcase-img, .box-white-card {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.border {
  border: 1px solid #cfccd8;
}

.border-top {
  border-top: 1px solid #cfccd8;
}

.border-right, .name-attr-type .attr-type-item:first-child {
  border-right: 1px solid #cfccd8;
}

.border-bottom, .name-attr-type {
  border-bottom: 1px solid #cfccd8;
}

.border-left {
  border-left: 1px solid #cfccd8;
}

.border-0 {
  border: 0;
}

.border-top-0 {
  border-top: 0;
}

.border-right-0 {
  border-right: 0;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-left-0 {
  border-left: 0;
}

.border-primary {
  border-color: #0089be;
}

.border-secondary {
  border-color: #47576c;
}

.border-success {
  border-color: #3f9d19;
}

.border-info {
  border-color: #19bbbf;
}

.border-warning {
  border-color: #ffbd28;
}

.border-danger {
  border-color: #bf3600;
}

.border-light {
  border-color: #f7f7f8;
}

.border-dark, .btn-special {
  border-color: #47576c;
}

.border-gray {
  border-color: #6c778e;
}

.border-gray-medium {
  border-color: #e3e1e9;
}

.border-white {
  border-color: #fff;
}

.rounded-sm {
  border-radius: 0.2rem;
}

.rounded, .form-control {
  border-radius: 0.25rem;
}

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-lg, .btn-options, .card, .name {
  border-radius: 0.3rem;
}

.rounded-circle, .icon-rounded, .feature-option-icon, .feel-level .slider {
  border-radius: 50%;
}

.rounded-pill, .pill {
  border-radius: 50rem;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block, .form-control {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .name, .name-attr-type, .feel-level, .feature-option, .showcase, .category-nav, .category-nav-items, .carousel-inner, .carousel-control {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex, .icon-rounded, .feature-option-icon {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap, .category-nav-items {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1, .d-flex-eq, .name-attr-type .attr-type-item, .feel-level {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center, .icon-rounded, .feature-option-icon, .name-only, .name, .category-nav-items, .carousel-control {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center, .icon-rounded, .feature-option-icon, .name-only, .name, .feel-level, .carousel-control {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative, .name-only, .name, .name h3, .feel-level, .header-home, .carousel-control {
  position: relative !important;
}

.position-absolute, .feel-level:after {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.075) !important;
}

.shadow, .card, .showcase-img {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100, .form-control {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100, .card, .box-white-card {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.mb-0, .name-only h3, .name h3, .header-name h1,
.my-0 {
  margin-bottom: 0;
}

.ml-0,
.mx-0 {
  margin-left: 0;
}

.m-1 {
  margin: 0.25rem;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem;
}

.mr-2, .btn-group-toggle > .btn:not(:last-child), .btn-group-toggle > .btn-special:not(:last-child), .btn-group-toggle > .btn-buy:not(:last-child), .btn-group-toggle > .pill:not(:last-child), .btn-group-toggle > .btn-cta:not(:last-child),
.btn-group-toggle > .btn-group > .btn:not(:last-child), .btn-group-toggle > .btn-group > .btn-special:not(:last-child), .btn-group-toggle > .btn-group > .btn-buy:not(:last-child), .btn-group-toggle > .btn-group > .pill:not(:last-child), .btn-group-toggle > .btn-group > .btn-cta:not(:last-child),
.mx-2,
.showcase-img {
  margin-right: 0.5rem;
}

.mb-2, .feature-option,
.my-2 {
  margin-bottom: 0.5rem;
}

.ml-2,
.mx-2,
.showcase-img {
  margin-left: 0.5rem;
}

.m-3 {
  margin: 1rem;
}

.mt-3, .header-name .hero-text-lead,
.my-3 {
  margin-top: 1rem;
}

.mr-3, .category-nav-items .pill:not(:last-child),
.mx-3 {
  margin-right: 1rem;
}

.mb-3, .btn-group-toggle > .btn-options, .category-nav-items .pill,
.my-3 {
  margin-bottom: 1rem;
}

.ml-3,
.mx-3 {
  margin-left: 1rem;
}

.m-4 {
  margin: 1.5rem;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem;
}

.mr-4, .feature-option-icon,
.mx-4 {
  margin-right: 1.5rem;
}

.mb-4, .section-form:not(:first-child), .form-floating,
.my-4 {
  margin-bottom: 1.5rem;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem;
}

.m-5 {
  margin: 3rem;
}

.mt-5, .header-home .hero-text-lead,
.my-5 {
  margin-top: 3rem;
}

.mr-5,
.mx-5 {
  margin-right: 3rem;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem;
}

.ml-5,
.mx-5 {
  margin-left: 3rem;
}

.m-6 {
  margin: 4rem;
}

.mt-6,
.my-6 {
  margin-top: 4rem;
}

.mr-6,
.mx-6 {
  margin-right: 4rem;
}

.mb-6, .showcase-img,
.my-6 {
  margin-bottom: 4rem;
}

.ml-6,
.mx-6 {
  margin-left: 4rem;
}

.m-7 {
  margin: 5rem;
}

.mt-7,
.my-7 {
  margin-top: 5rem;
}

.mr-7,
.mx-7 {
  margin-right: 5rem;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem;
}

.ml-7,
.mx-7 {
  margin-left: 5rem;
}

.p-0 {
  padding: 0;
}

.pt-0,
.py-0 {
  padding-top: 0;
}

.pr-0,
.px-0 {
  padding-right: 0;
}

.pb-0,
.py-0 {
  padding-bottom: 0;
}

.pl-0,
.px-0 {
  padding-left: 0;
}

.p-1 {
  padding: 0.25rem;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem;
}

.p-2, .showcase-img {
  padding: 0.5rem;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.pt-3, .hero-text,
.py-3,
.name-attr-type .attr-type-item,
.header .nav-container {
  padding-top: 1rem;
}

.pr-3,
.px-3,
.pill,
.carousel-control {
  padding-right: 1rem;
}

.pb-3, .carousel-inner,
.py-3,
.name-attr-type .attr-type-item,
.header .nav-container {
  padding-bottom: 1rem;
}

.pl-3,
.px-3,
.pill,
.carousel-control {
  padding-left: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.pt-4, .category-nav,
.py-4,
.name-only {
  padding-top: 1.5rem;
}

.pr-4,
.px-4,
.name-only,
.name,
.name-attr-type .attr-type-item,
.name-attr-feel,
.box-white-card {
  padding-right: 1.5rem;
}

.pb-4, .hero-text,
.py-4,
.name-only {
  padding-bottom: 1.5rem;
}

.pl-4,
.px-4,
.name-only,
.name,
.name-attr-type .attr-type-item,
.name-attr-feel,
.box-white-card {
  padding-left: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.pt-5, .features,
.py-5,
.container-main,
.section-full,
.section-full-dark,
.name,
.box-white-card {
  padding-top: 3rem;
}

.pr-5,
.px-5 {
  padding-right: 3rem;
}

.pb-5,
.py-5,
.container-main,
.section-full,
.section-full-dark,
.name,
.box-white-card {
  padding-bottom: 3rem;
}

.pl-5,
.px-5 {
  padding-left: 3rem;
}

.p-6 {
  padding: 4rem;
}

.pt-6,
.py-6,
.container-main-lg {
  padding-top: 4rem;
}

.pr-6,
.px-6 {
  padding-right: 4rem;
}

.pb-6,
.py-6,
.container-main-lg {
  padding-bottom: 4rem;
}

.pl-6,
.px-6 {
  padding-left: 4rem;
}

.p-7 {
  padding: 5rem;
}

.pt-7,
.py-7 {
  padding-top: 5rem;
}

.pr-7,
.px-7 {
  padding-right: 5rem;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem;
}

.pl-7,
.px-7 {
  padding-left: 5rem;
}

.m-n1 {
  margin: -0.25rem;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem;
}

.m-n2 {
  margin: -0.5rem;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem;
}

.m-n3 {
  margin: -1rem;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem;
}

.m-n4 {
  margin: -1.5rem;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem;
}

.m-n5 {
  margin: -3rem;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem;
}

.m-n6 {
  margin: -4rem;
}

.mt-n6,
.my-n6 {
  margin-top: -4rem;
}

.mr-n6,
.mx-n6 {
  margin-right: -4rem;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4rem;
}

.ml-n6,
.mx-n6 {
  margin-left: -4rem;
}

.m-n7 {
  margin: -5rem;
}

.mt-n7,
.my-n7 {
  margin-top: -5rem;
}

.mr-n7,
.mx-n7 {
  margin-right: -5rem;
}

.mb-n7,
.my-n7 {
  margin-bottom: -5rem;
}

.ml-n7,
.mx-n7 {
  margin-left: -5rem;
}

.m-auto {
  margin: auto;
}

.mt-auto,
.my-auto {
  margin-top: auto;
}

.mr-auto,
.mx-auto,
.hero-text-lead {
  margin-right: auto;
}

.mb-auto,
.my-auto {
  margin-bottom: auto;
}

.ml-auto,
.mx-auto,
.hero-text-lead {
  margin-left: auto;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0;
  }
  .m-sm-1 {
    margin: 0.25rem;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem;
  }
  .m-sm-2 {
    margin: 0.5rem;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem;
  }
  .m-sm-3 {
    margin: 1rem;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem;
  }
  .m-sm-4 {
    margin: 1.5rem;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem;
  }
  .m-sm-5 {
    margin: 3rem;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem;
  }
  .m-sm-6 {
    margin: 4rem;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem;
  }
  .m-sm-7 {
    margin: 5rem;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem;
  }
  .p-sm-0 {
    padding: 0;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0;
  }
  .p-sm-1 {
    padding: 0.25rem;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem;
  }
  .p-sm-2 {
    padding: 0.5rem;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem;
  }
  .p-sm-3 {
    padding: 1rem;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem;
  }
  .p-sm-4 {
    padding: 1.5rem;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem;
  }
  .p-sm-5 {
    padding: 3rem;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem;
  }
  .p-sm-6 {
    padding: 4rem;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem;
  }
  .p-sm-7 {
    padding: 5rem;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem;
  }
  .m-sm-n1 {
    margin: -0.25rem;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem;
  }
  .m-sm-n2 {
    margin: -0.5rem;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem;
  }
  .m-sm-n3 {
    margin: -1rem;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem;
  }
  .m-sm-n4 {
    margin: -1.5rem;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem;
  }
  .m-sm-n5 {
    margin: -3rem;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem;
  }
  .m-sm-n6 {
    margin: -4rem;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem;
  }
  .m-sm-n7 {
    margin: -5rem;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0;
  }
  .m-md-1 {
    margin: 0.25rem;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem;
  }
  .m-md-2 {
    margin: 0.5rem;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem;
  }
  .m-md-3 {
    margin: 1rem;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem;
  }
  .m-md-4 {
    margin: 1.5rem;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem;
  }
  .m-md-5 {
    margin: 3rem;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem;
  }
  .m-md-6 {
    margin: 4rem;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem;
  }
  .m-md-7 {
    margin: 5rem;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem;
  }
  .p-md-0 {
    padding: 0;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0;
  }
  .p-md-1 {
    padding: 0.25rem;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem;
  }
  .p-md-2 {
    padding: 0.5rem;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem;
  }
  .p-md-3 {
    padding: 1rem;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem;
  }
  .p-md-4 {
    padding: 1.5rem;
  }
  .pt-md-4, .hero-text,
  .py-md-4 {
    padding-top: 1.5rem;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem;
  }
  .p-md-5 {
    padding: 3rem;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem;
  }
  .pb-md-5, .hero-text,
  .py-md-5 {
    padding-bottom: 3rem;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem;
  }
  .p-md-6 {
    padding: 4rem;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem;
  }
  .p-md-7 {
    padding: 5rem;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem;
  }
  .m-md-n1 {
    margin: -0.25rem;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem;
  }
  .m-md-n2 {
    margin: -0.5rem;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem;
  }
  .m-md-n3 {
    margin: -1rem;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem;
  }
  .m-md-n4 {
    margin: -1.5rem;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem;
  }
  .m-md-n5 {
    margin: -3rem;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem;
  }
  .m-md-n6 {
    margin: -4rem;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem;
  }
  .m-md-n7 {
    margin: -5rem;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem;
  }
  .m-md-auto {
    margin: auto;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0;
  }
  .mr-lg-0, .feature-option-right .feature-option-icon,
  .mx-lg-0 {
    margin-right: 0;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0;
  }
  .m-lg-1 {
    margin: 0.25rem;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem;
  }
  .m-lg-2 {
    margin: 0.5rem;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem;
  }
  .m-lg-3 {
    margin: 1rem;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem;
  }
  .m-lg-4 {
    margin: 1.5rem;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem;
  }
  .ml-lg-4, .feature-option-right .feature-option-icon,
  .mx-lg-4 {
    margin-left: 1.5rem;
  }
  .m-lg-5 {
    margin: 3rem;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem;
  }
  .m-lg-6 {
    margin: 4rem;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem;
  }
  .m-lg-7 {
    margin: 5rem;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem;
  }
  .p-lg-0 {
    padding: 0;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0;
  }
  .p-lg-1 {
    padding: 0.25rem;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem;
  }
  .p-lg-2 {
    padding: 0.5rem;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem;
  }
  .p-lg-3 {
    padding: 1rem;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem;
  }
  .p-lg-4, .feature-option {
    padding: 1.5rem;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem;
  }
  .p-lg-5 {
    padding: 3rem;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem;
  }
  .p-lg-6 {
    padding: 4rem;
  }
  .pt-lg-6, .features,
  .py-lg-6 {
    padding-top: 4rem;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem;
  }
  .p-lg-7 {
    padding: 5rem;
  }
  .pt-lg-7,
  .py-lg-7,
  .container-main-lg,
  .section-full,
  .section-full-dark {
    padding-top: 5rem;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem;
  }
  .pb-lg-7,
  .py-lg-7,
  .container-main-lg,
  .section-full,
  .section-full-dark {
    padding-bottom: 5rem;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem;
  }
  .m-lg-n1 {
    margin: -0.25rem;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem;
  }
  .m-lg-n2 {
    margin: -0.5rem;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem;
  }
  .m-lg-n3 {
    margin: -1rem;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem;
  }
  .m-lg-n4 {
    margin: -1.5rem;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem;
  }
  .m-lg-n5 {
    margin: -3rem;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem;
  }
  .m-lg-n6 {
    margin: -4rem;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem;
  }
  .m-lg-n7 {
    margin: -5rem;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto;
  }
}

@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0;
  }
  .m-xl-1 {
    margin: 0.25rem;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem;
  }
  .m-xl-2 {
    margin: 0.5rem;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem;
  }
  .m-xl-3 {
    margin: 1rem;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem;
  }
  .m-xl-4 {
    margin: 1.5rem;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem;
  }
  .m-xl-5 {
    margin: 3rem;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem;
  }
  .m-xl-6 {
    margin: 4rem;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem;
  }
  .m-xl-7 {
    margin: 5rem;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem;
  }
  .p-xl-0 {
    padding: 0;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0;
  }
  .p-xl-1 {
    padding: 0.25rem;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem;
  }
  .p-xl-2 {
    padding: 0.5rem;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem;
  }
  .p-xl-3 {
    padding: 1rem;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem;
  }
  .p-xl-4 {
    padding: 1.5rem;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem;
  }
  .p-xl-5 {
    padding: 3rem;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem;
  }
  .p-xl-6 {
    padding: 4rem;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem;
  }
  .p-xl-7 {
    padding: 5rem;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem;
  }
  .m-xl-n1 {
    margin: -0.25rem;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem;
  }
  .m-xl-n2 {
    margin: -0.5rem;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem;
  }
  .m-xl-n3 {
    margin: -1rem;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem;
  }
  .m-xl-n4 {
    margin: -1.5rem;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem;
  }
  .m-xl-n5 {
    margin: -3rem;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem;
  }
  .m-xl-n6 {
    margin: -4rem;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem;
  }
  .m-xl-n7 {
    margin: -5rem;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto;
  }
}

.stretched-link::after, .name-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right, .nav-item {
  text-align: right !important;
}

.text-center, .name-attr-type .attr-type-item, .header, .hero-text-lead {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right, .feature-option-right .feature-option-text {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold, .btn-buy, .btn-cta, .name-attr-type .attr-type-item {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white, .section-full-dark, h1, .icon-rounded.bg-primary, .feature-option-icon, .name, .header {
  color: #fff !important;
}

.text-primary {
  color: #0089be !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #005272 !important;
}

.text-secondary {
  color: #47576c !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #29323e !important;
}

.text-success {
  color: #3f9d19 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #255b0e !important;
}

.text-info {
  color: #19bbbf !important;
}

a.text-info:hover, a.text-info:focus {
  color: #10797b !important;
}

.text-warning {
  color: #ffbd28 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #db9700 !important;
}

.text-danger {
  color: #bf3600 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #732000 !important;
}

.text-light {
  color: #f7f7f8 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #ceced4 !important;
}

.text-dark {
  color: #47576c !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #29323e !important;
}

.text-gray {
  color: #6c778e !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #4b5363 !important;
}

.text-gray-medium {
  color: #e3e1e9 !important;
}

a.text-gray-medium:hover, a.text-gray-medium:focus {
  color: #bab5c9 !important;
}

.text-body {
  color: #344254 !important;
}

.text-muted {
  color: #6c778e !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn):not(.btn-special):not(.btn-buy):not(.pill):not(.btn-cta) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 1200px !important;
  }
  .container, .cards, .customers, .header .nav-container, .hero-text-lead, .category-nav {
    min-width: 1200px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #cfccd8 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #cfccd8;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #cfccd8;
  }
}

body, .page-wrapper {
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans';
  src: local("Helvetica"), local("Helvetica"), Lato;
  font-display: swap;
}

.container-main {
  background-color: #f7f7f8;
}

.cards .col {
  margin-bottom: 40px;
}

.bg-section {
  position: relative;
}

.bg-section::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.bg-confetti::before {
  z-index: -1;
  background: linear-gradient(#fff 0, rgba(255, 255, 255, 0.8) 100%), url("../img/bg-confetti.svg") 50% 100% repeat-x;
  background-size: cover, 800px;
}

.bg-round::before {
  top: 0;
  background: url("../img/bg-round.svg") 50% 0 repeat #0089be;
  background-size: 80px;
}

h1, h2, h3 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 3.5rem;
}

@media (max-width: 1200px) {
  h1 {
    font-size: calc(1.475rem + 2.7vw) ;
  }
}

h1 .small, h1 footer {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h1 .small, h1 footer {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

h2 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h2 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

dt {
  font-size: 1.2rem;
}

.align-right {
  right: 0;
  left: auto;
}

.text-faded, .name-link, .hero-text-lead {
  opacity: .75;
}

.text-darker {
  color: #e3e1e9;
}

.d-flex-eq {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.bg-special {
  background-color: #342061;
}

.link-dark, .name-link {
  color: #fff;
  text-decoration: underline;
  background-color: transparent;
}

.link-dark:hover, .name-link:hover {
  color: #fff;
  text-decoration: underline;
}

.link-no-decoration, .name-link, .link-no-decoration:hover, .name-link:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .header-width-clamp-md .max-content-width {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .header-width-clamp-md .min-content-width {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
}

.btn-special {
  padding: 0.75rem 1.5rem;
  font-size: 1.375rem;
  line-height: 1.7;
  border-radius: 50rem;
  letter-spacing: .2px;
}

@media (max-width: 1200px) {
  .btn-special {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.btn-buy {
  letter-spacing: .2px;
}

.btn-cta {
  min-width: 200px;
}

.btn-options {
  color: #cfccd8;
  border-color: #cfccd8;
  color: #344254;
}

.btn-options:hover {
  color: #344254;
  background-color: #cfccd8;
  border-color: #cfccd8;
}

.btn-options:focus, .btn-options.focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 204, 216, 0.5);
}

.btn-options.disabled, .btn-options:disabled {
  color: #cfccd8;
  background-color: transparent;
}

.btn-options:not(:disabled):not(.disabled):active, .btn-options:not(:disabled):not(.disabled).active,
.show > .btn-options.dropdown-toggle {
  color: #344254;
  background-color: #cfccd8;
  border-color: #cfccd8;
}

.btn-options:not(:disabled):not(.disabled):active:focus, .btn-options:not(:disabled):not(.disabled).active:focus,
.show > .btn-options.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 204, 216, 0.5);
}

.icon {
  width: 48px;
  height: 48px;
}

.icon-rounded, .feature-option-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.icon-rounded-lg, .feature-option-icon {
  width: 4rem;
  height: 4rem;
}

.navbar-nav {
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
  padding-inline-start: 0;
}

.name-blue {
  background-color: #0089be;
}

a.name-blue:hover, a.name-blue:focus,
button.name-blue:hover,
button.name-blue:focus {
  background-color: #00648b;
}

.name-gradient-blue {
  background: #0089be linear-gradient(180deg, #0074a2, #0089be) repeat-x !important;
}

.name-cyan {
  background-color: #19bbbf;
}

a.name-cyan:hover, a.name-cyan:focus,
button.name-cyan:hover,
button.name-cyan:focus {
  background-color: #138f92;
}

.name-gradient-cyan {
  background: #19bbbf linear-gradient(180deg, #159fa2, #19bbbf) repeat-x !important;
}

.name-dkblue {
  background-color: #001e5e;
}

a.name-dkblue:hover, a.name-dkblue:focus,
button.name-dkblue:hover,
button.name-dkblue:focus {
  background-color: #000e2b;
}

.name-gradient-dkblue {
  background: #001e5e linear-gradient(180deg, #001a50, #001e5e) repeat-x !important;
}

.name-dkcyan {
  background-color: #177d7f;
}

a.name-dkcyan:hover, a.name-dkcyan:focus,
button.name-dkcyan:hover,
button.name-dkcyan:focus {
  background-color: #0f5354;
}

.name-gradient-dkcyan {
  background: #177d7f linear-gradient(180deg, #146a6c, #177d7f) repeat-x !important;
}

.name-dkred {
  background-color: #7a222f;
}

a.name-dkred:hover, a.name-dkred:focus,
button.name-dkred:hover,
button.name-dkred:focus {
  background-color: #521720;
}

.name-gradient-dkred {
  background: #7a222f linear-gradient(180deg, #681d28, #7a222f) repeat-x !important;
}

.name-dkyellow {
  background-color: #917e10;
}

a.name-dkyellow:hover, a.name-dkyellow:focus,
button.name-dkyellow:hover,
button.name-dkyellow:focus {
  background-color: #63560b;
}

.name-gradient-dkyellow {
  background: #917e10 linear-gradient(180deg, #7b6b0e, #917e10) repeat-x !important;
}

.name-green {
  background-color: #3f9d19;
}

a.name-green:hover, a.name-green:focus,
button.name-green:hover,
button.name-green:focus {
  background-color: #2d7112;
}

.name-gradient-green {
  background: #3f9d19 linear-gradient(180deg, #368515, #3f9d19) repeat-x !important;
}

.name-orange {
  background-color: #d66b30;
}

a.name-orange:hover, a.name-orange:focus,
button.name-orange:hover,
button.name-orange:focus {
  background-color: #b05523;
}

.name-gradient-orange {
  background: #d66b30 linear-gradient(180deg, #b65b29, #d66b30) repeat-x !important;
}

.name-pink {
  background-color: #7e0d7e;
}

a.name-pink:hover, a.name-pink:focus,
button.name-pink:hover,
button.name-pink:focus {
  background-color: #500850;
}

.name-gradient-pink {
  background: #7e0d7e linear-gradient(180deg, #6b0b6b, #7e0d7e) repeat-x !important;
}

.name-purple {
  background-color: #554a94;
}

a.name-purple:hover, a.name-purple:focus,
button.name-purple:hover,
button.name-purple:focus {
  background-color: #413972;
}

.name-gradient-purple {
  background: #554a94 linear-gradient(180deg, #483f7e, #554a94) repeat-x !important;
}

.name-red {
  background-color: #bf3600;
}

a.name-red:hover, a.name-red:focus,
button.name-red:hover,
button.name-red:focus {
  background-color: #8c2800;
}

.name-gradient-red {
  background: #bf3600 linear-gradient(180deg, #a22e00, #bf3600) repeat-x !important;
}

.name-yellow {
  background-color: #ffbd28;
}

a.name-yellow:hover, a.name-yellow:focus,
button.name-yellow:hover,
button.name-yellow:focus {
  background-color: #f4a900;
}

.name-gradient-yellow {
  background: #ffbd28 linear-gradient(180deg, #d9a122, #ffbd28) repeat-x !important;
}

.name-only h3 {
  font-size: 2rem;
  font-weight: normal;
}

@media (max-width: 1200px) {
  .name-only h3 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

.name {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.name h3 {
  font-size: 2.5rem;
  z-index: 1;
}

@media (max-width: 1200px) {
  .name h3 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

.name-link::after {
  transition: background .4s;
}

.name-link:hover::after {
  background: rgba(0, 0, 0, 0.1);
}

.name-link:hover, .name-link:focus {
  text-decoration: none;
}

.name-attr-type .attr-type-item {
  border-radius: 0;
  transition: background .4s;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.name-attr-type .attr-type-item:hover, .name-attr-type .attr-type-item:focus {
  text-decoration: none;
}

.name-attr-feel {
  display: grid;
  grid-template-columns: -webkit-min-content auto -webkit-min-content;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  gap: 1rem;
}

.feel-level .slider {
  width: 0.9625rem;
  height: 0.9625rem;
  background-color: #ffbd28;
}

.feel-level:after {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #ffbd28;
  top: 50%;
}

.features {
  background-color: #f1f1f3;
}

.feature-option-text {
  -ms-flex: 1;
  flex: 1;
}

.showcase {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.showcase::-webkit-scrollbar {
  display: none;
}

.showcase-img img {
  width: 400px;
}

.header {
  background: linear-gradient(#2f3041 0, #17171e 59%, #1b1c24 100%);
}

.header-home {
  background: url("../img/pattern.svg") repeat, radial-gradient(circle, #2f3041 0, #17171e 59%, #1b1c24 100%);
  background-size: 180px, auto;
}

.header-home h1 {
  font-family: 'Courier New', monospace;
  font-size: 3rem;
}

@media (max-width: 1200px) {
  .header-home h1 {
    font-size: calc(1.425rem + 2.1vw) ;
  }
}

.header-home .hero-text-lead {
  color: #cabfe3;
}

.hero-text-lead {
  max-width: 800px;
}

.logo {
  margin-right: 0;
  font-size: 1.375rem;
  line-height: inherit;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 100px;
  background-repeat: no-repeat;
}

.logo:hover, .logo:focus {
  text-decoration: none;
  color: transparent;
}

footer {
  color: #f7f7f8;
  background-color: #1b1c24;
}

.form-control {
  padding: .5rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.7;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem 1rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.carousel-indicators {
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

.carousel-bullets [data-target] {
  box-sizing: content-box;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0089be;
  background-clip: padding-box;
  border: 10px solid transparent;
  border-radius: 50%;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-bullets .active {
  opacity: 1;
}

.carousel-control {
  z-index: 1;
}

#carousel-names-sold {
  /*
	code by Iatek LLC 2018 - CC 2.0 License - Attribution required
	code customized by Azmind.com
*/
  /* MD */
  /* LG */
}

@media (min-width: 768px) and (max-width: 991px) {
  #carousel-names-sold {
    /* Show 4th slide on md if col-md-4*/
  }
  #carousel-names-sold .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #carousel-names-sold {
    /* Show 3rd slide on sm if col-sm-6*/
  }
  #carousel-names-sold .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 576px) {
  #carousel-names-sold {
    /* show 2 items */
    /* left or forward direction */
    /* farthest right hidden item must be also positioned for animations */
    /* right or prev direction */
  }
  #carousel-names-sold .carousel-item {
    margin-right: 0;
  }
  #carousel-names-sold .carousel-inner .active + .carousel-item {
    display: block;
  }
  #carousel-names-sold .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  #carousel-names-sold .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none;
  }
  #carousel-names-sold .carousel-inner .carousel-item-next {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #carousel-names-sold .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  #carousel-names-sold .carousel-item-next.carousel-item-left + .carousel-item,
  #carousel-names-sold .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
    position: relative;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  #carousel-names-sold .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  #carousel-names-sold .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  #carousel-names-sold .carousel-item-prev.carousel-item-right + .carousel-item,
  #carousel-names-sold .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
    position: relative;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 768px) {
  #carousel-names-sold {
    /* show 3rd of 3 item slide */
    /* left or forward direction */
    /* right or prev direction */
  }
  #carousel-names-sold .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  #carousel-names-sold .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }
  #carousel-names-sold .carousel-inner .carousel-item-next {
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #carousel-names-sold .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  #carousel-names-sold .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

@media (min-width: 991px) {
  #carousel-names-sold {
    /* show 4th item */
    /* Show 5th slide on lg if col-lg-3 */
    /* left or forward direction */
    /* right or prev direction //t - previous slide direction last item animation fix */
  }
  #carousel-names-sold .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
  }
  #carousel-names-sold .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none;
  }
  #carousel-names-sold .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
  #carousel-names-sold .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  #carousel-names-sold .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}

.accordion-button {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 1rem 1.25rem;
  color: #0089be;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0089be;
  background-color: #fff;
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230089be'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23344254'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: rgba(0, 0, 0, 0.125);
  border-bottom-width: 0;
  outline: 0;
  box-shadow: none;
}

.accordion-button:focus.collapsing {
  border-bottom-color: transparent;
}

.accordion-button:focus.collapsed {
  border-bottom-width: 1px;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 1px;
  border-top-width: 0;
}

.accordion-body {
  padding: 1rem 1.25rem;
  background-color: #fff;
}
/*# sourceMappingURL=bootstrap.css.map */